.navbar {
    background: linear-gradient(90deg, rgb(1, 151, 176) 0%, rgb(11, 151, 176) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }

  

  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20spx;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    width:40%;
    margin-top:25px;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
    font-weight: 900;
    margin-top:20px;
  }
  
  .nav-item {
    height: 80px;
    
  }

 
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1.5rem 1.5rem;
    height:30px;
    margin-top:30px;
    
  }
  
  .nav-links:hover {
    
    transition: all 0.2s ease-out;
  }
  .nav-item a {
    font-size:20px;
    padding:0;
    padding-right:20px;
    margin-right:20px;
  }

  .nav-item i {
    font-size:20px;
    padding:0;
   
  }
 
  .nav-link-text{
   font-size:20px;
  }
  
  .fa-bars {
    color: #fff;
    margin-left:30px;
    margin-top:7px;
    font-weight: 900;
  }
  
  .fa-times {

    margin-top:3px;
    margin-right:3px;
  }
 .fas{
     margin-left:20px;
    font-weight: 900;
 }
 .nav-link-text{
    margin-left:20px;

 }

 .nav-link-text:hover{
      color:white;
      text-decoration: none;
 }
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  @media only screen and (max-width: 645px) {
    .navbar-logo{
      display: none;
    }
    .nav-link-text:hover{
      margin-left:20px;
      color:linear-gradient(90deg, rgb(1, 151, 176) 0%, rgb(11, 151, 176) 100%);
   }
  }

  @media only screen and (max-width: 1305px) {
    .navbar-logo{
      display: none;
    }
    .nav-link-text{
      
  
   }
  
  }
  @media only screen and (max-width: 1231px) {
    .navbar-logo{
      display: none;
    }
    .nav-link-text:hover{
      margin-left:20px;
      color:black;
   }
  }


  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 40vh;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      margin-right:50px;
    }
  
    .nav-menu.active {
      background: linear-gradient(90deg, rgb(1, 151, 176) 0%, rgb(11, 151, 176) 100%);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;

    }

    .nav-links {
      text-align: center;
      width: 100%;
      display: fixed;
      vertical-align: top;
  
    }
  
    
  
    .navbar-logo {
      position: fixed;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
      
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 10%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
      
    }

  }