body{
background-color: #1e1e1e;
}
.wrapper
{


justify-content: center;
display:flex;
position: relative;
margin-top:100px;
}

.left{
    max-width:400px;
    width:100%;
    box-shadow: 2px 0px 1px -2px white;
    height:100%;
    margin-top:20px;
    
}
.right{
    max-width:400px;
    width:100%;
    padding-bottom:200px;
}

a{
    text-decoration: none;
}

p:hover{
    opacity:0.5;
}

.contact-info{
    padding:30px;
    margin-left:40px;
    font-size:25px;

}
.far{
    padding-right:20px;
}
.fab{
    padding-right:20px;
}
p{

    color:white;
    font-size:30px;
    justify-content: center;
}

.contact_form_div
{
 width:330px;
 
 padding:10px;

}

.contact_form_div input[type="text"]
{
 width:330px;
 height:40px;
 border-radius:2px;
 font-size:17px;
 padding-left:5px;
 border:none;
 background-color: #1e1e1e;
 margin-bottom:30px;
 box-shadow: 0 2px 1px -2px white;
 caret-color: white;
 color:white;

}
.contact_form_div input[type="email"]
{
 width:330px;
 height:40px;
 border-radius:2px;
 font-size:17px;
 padding-left:5px;
 border:none;
 background-color: #1e1e1e;
 margin-bottom:30px;
 box-shadow: 0 2px 1px -2px white;
 caret-color: white;
 color:white;
}
.contact_form_div textarea
{
 width:330px;
 height:70px;
 border-radius:2px;
 font-size:17px;
 padding:5px;
 background-color: #1e1e1e;
 margin-bottom:30px;
 border:none;
 box-shadow: 0 2px 1px -2px white;
 caret-color: white;
 color:white;
}
.contact_form_div input[type="submit"]
{
 width:330px;
 height:40px;
 border:none;
 border-radius:2px;
 font-size:17px;
 background-color:#1e1e1e;
 background-color:#1e1e1e;
 color:white;
 font-weight:bold;
 cursor: pointer;
}
.contact_form_div input[type="submit"]:hover
{
 width:330px;
 height:40px;
 border:none;
 border-radius:2px;
 font-size:17px;
 background-color:#1e1e1e;
 color:white;
 font-weight:bold;
 cursor: pointer;
 opacity:0.5;
}
.contact_label{
    padding-bottom:10px;
}


@media only screen and (min-width:700px) and (max-width:995px)
{

 .wrapper
 {
 flex-direction: column;
 }
 .left{
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width:100%;
    max-width:700px;
    box-shadow: 0px 0px 0px 0px white;
}
.right{
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width:100%;
    max-width:700px;
}

.contact-info{
    
    
    margin-right:40px;

}
.far{
    padding-right:20px;
}
.fab{
    padding-right:20px;
}
 
 .contact_form_div
 {
  width:50%;
  margin-left:25%;
  padding-left:0px;
  padding-right:0px;
 }
 .contact_form_div input[type="text"]
 {
  width:80%;
 }
 .contact_form_div input[type="email"]
 {
  width:80%;
 }
 .contact_form_div textarea
 {
  width:80%;
 }
 .contact_form_div input[type="submit"]
 {
  width:80%;
 }
}
@media only screen and (min-width:400px) and (max-width:699px)
{
.wrapper
{
 flex-direction: column;
}
 .wrapper h1
 {
  font-size:30px;
 }

 .left{
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width:100%;
    max-width:700px;
    box-shadow: 0px 0px 0px 0px white;
}
.right{
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width:100%;
    max-width:700px;
}
.contact-info{
    
    
    margin-right:40px;

}
.far{
    padding-right:20px;
}
.fab{
    padding-right:20px;
}
 
 .contact_form_div
 {
  width:60%;
  margin-left:20%;
 }
 .contact_form_div input[type="text"]
 {
  width:80%;
 }
 .contact_form_div textarea
 {
  width:80%;
 }
 .contact_form_div input[type="submit"]
 {
  width:80%;
 }
 .contact_form_div input[type="email"]
 {
  width:80%;
 }
}
@media only screen and (min-width:100px) and (max-width:399px)
{
.wrapper
{
 flex-direction: column;
 justify-content: center;
 }

 .left{
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width:100%;
    max-width:700px;
    box-shadow: 0px 0px 0px 0px white;
}
.right{
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width:100%;
    max-width:700px;
}
.contact-info{
    
    
    margin-right:40px;

}
.far{
    padding-right:20px;
}
.fab{
    padding-right:20px;
}
 .wrapper h1
 {
  font-size:25px;
 }
 .contact_form_div
 {
  width:90%;
  margin-left:5%;
  padding-left:0px;
  padding-right:0px;
 }
 .contact_form_div input[type="text"]
 {
  width:80%;
 }
 .contact_form_div textarea
 {
  width:80%;
 }
 .contact_form_div input[type="email"]
 {
  width:80%;
 }
 .contact_form_div input[type="submit"]
 {
  width:80%;
 }
}