.wrapper
{


justify-content: center;
display:flex;
position: relative;
margin-top:100px;
}

.left{
    max-width:400px;
    width:100%;
    box-shadow: 2px 0px 1px -2px white;
    height:100%;
    
}
.right{
    max-width:500px;
    width:100%;
    padding-bottom:200px;
}

.chris{
    width:60%;
    border-radius: 5%;

}

.chrisText{
    text-align: left;
    font-size:20px;
    padding:30px;
}
.MainAbout{
    padding-bottom:100px;
    
}

.MainAbout:hover{
    opacity: 0.5;
    
}


.MainAbout1{
    margin-top:50px;
    font-size:30px;
}
.EtsyLinkHome{
    font-size:30px;
    }
.EtsyLinkHome:hover{
opacity:0.5;
}